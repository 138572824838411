import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

const isBrowser = typeof window !== 'undefined'

class Template extends React.Component {
  render() {
    const { children } = this.props
    const isEn = isBrowser ? window.location.pathname.startsWith('/en/') : false
    const lang = isEn ? 'en' : 'it'

    const Container = styled.div`
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-weight: 400;
      font-family: 'Nunito';
      max-width: 780px;
      width: 780px;
      margin: 0 auto;
      @media (max-width: 700px) {
        width: 80vw;
      }
    `
    const StyledLink = styled(Link)`
      position: relative;
      text-decoration: none;
      background-image: none;
      &:hover {
        text-decoration: none !important;
        background-image: none;
      }
    `
    const Title = styled.h1`
      display: flex;
      font-weight: 600;
      font-family: 'Lato';
      font-size: 2.6vw !important;
      margin: 10vh 0;
      @media (max-width: 700px) {
        letter-spacing: 0.2rem;
        text-transform: uppercase;
        font-size: 13px !important;
      }
    `
    const Content = styled.div`
      /* font-weight: 400;
      font-family: 'EB Garamond';
      font-size: 1.4vw;
      line-height: 170%; */
    `
    return (
      <Container>
        <StyledLink to={'/dev/'}>
          <Title>Andrea Martines</Title>
        </StyledLink>
        <Content className="content">{children}</Content>
      </Container>
    )
  }
}

export default Template
