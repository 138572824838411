import { graphql } from 'gatsby'
import * as React from 'react'
import Layout from './layout'
import Css from './css'
import {filterHtml} from '../utils'
// import Seo from "../components/seo";

export default function DevPageTemplate({ data: { markdownRemark, allFile } }) {
  const { frontmatter, html } = markdownRemark
  var filteredHtml = filterHtml(allFile, html)

  return (
    <>
    <style>{Css}</style>
    <Layout>
      {/* <h1>{frontmatter.title}</h1> */}
      <div dangerouslySetInnerHTML={{ __html: filteredHtml }} />
    </Layout>
    </>
  )
}

export const pageQuery = graphql`
  query ($permalink: String!) {
    markdownRemark(frontmatter: { permalink: { eq: $permalink } }) {
      html
      frontmatter {
        date(formatString: "DD MMMM YYYY")
        permalink
        title
      }
    }
    allFile(filter: { sourceInstanceName: { eq: "uploads" } }) {
      edges {
        node {
          publicURL
          relativePath
        }
      }
    }
  }
`
