import React from 'react'

const Css = `
body {
    background: #fff;
}
.selected {
    background: #222;
    color: #fff;
    border-radius: 4px;
    padding: 0px 5px 3px 4px;
    }
.content {
    font-weight: 300;
    font-family: 'Arial';
    font-size: 1.6vw;
    line-height: 170%;
}
a, a:hover, a:visited {
    color: #000;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 94%, #222 96%, #222 100%);
}
.entry-meta {
    float: right;
    margin-top: -57px;
}
@media (max-width: 700px) {
    .content {
        font-size: 14px;
    }
    .entry-meta {
        margin-top: -40px;
    }
  }
h3 {
    margin-top: 2em;
}
// .logo {
//     width: 30%;
//     float: left;
//     text-align: center;
// }
.logo img {
    width: 150px;
    margin-top: 50px;
}
h4 a {
    display: none;
}
// h4 {
//     width: 70%;
//     float: right;
//     text-align: left;
// }

`

export default Css
